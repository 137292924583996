<template>
  <div class="personalCenterContainer">
    <div class="personalCenterTitle">
      <span>角色管理</span>
    </div>
    <div class="staffRoleContainer">
      <div class="addRole">
        <el-button type="danger">新增角色</el-button>
      </div>
      <div class="tableList">
        <el-table :data="tableDatas">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="roleName" label="角色名称"></el-table-column>
          <el-table-column prop="roleDesc" label="角色描述"></el-table-column>  
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="warning" size="mini">设置权限</el-button>
              <el-button type="primary" size="mini" @click="showModifyDialog(scope.row)">修改角色</el-button>
              <el-button type="danger" size="mini" @click="removeItem(scope.row)">删除角色</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        ></el-pagination>
      </div>
      <!-- 修改角色 -->
      <el-dialog
      v-dialogDrag
        title="修改角色"
        :close-on-click-modal="false"
        :visible.sync="modifyDialogVisible"
        width="400px"
        :before-close="modifyHandleClose">
        <el-form ref="form" :model="form" :rules="rules" label-position="top">
          <el-form-item label="角色名称" prop="roleName">
            <el-input v-model="form.roleName" placeholder="请输入角色名称"></el-input>
          </el-form-item>
          <el-form-item label="角色描述" prop="roleDesc">
            <el-input v-model="form.roleDesc" placeholder="请输入角色描述"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="modifyHandleClose()">取 消</el-button>
          <el-button type="primary" @click="confirmModify()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      totalPage: null,
      tableDatas: [
        {
          roleName: "管理员",
          roleDesc: "管理员角色描述"
        }
      ],
      form: {
        roleName: null,
        roleDesc: null
      },
      rules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
        ],
        roleDesc: [
          { required: true, message: '请输入角色描述', trigger: 'blur' },
        ]
      },
      modifyDialogVisible: false
    };
  },
  mounted() {
    // this.getDatas();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    getDatas() {
      protocolFwd.param_queryPresaleResources.param = {
        page: this.current - 1,
        size: this.pageSize,
        sort: [],
        firmId: this.sessionInfoGetter.firmId,
        commodityName: this.searchForm.commodityName,
        statusStr: this.searchForm.statusStr,
        orderStatus: this.searchForm.orderStatus
      };
      http.postFront(protocolFwd.param_queryPresaleResources).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.tableDatas = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    showModifyDialog(item) {
      const tempData = JSON.parse(JSON.stringify(item));
      this.modifyDialogVisible = true;
    },
    confirmModify() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          
        }
      });
    },
    removeItem(item) {
      this.$confirm('请确认是否删除该角色？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        
      }).catch(() => {
          
      });
    },
    modifyHandleClose() {
      this.modifyDialogVisible = false;
      this.$refs['form'].resetFields();
    }
  }
};
</script>
<style lang="scss" scoped>
.personalCenterContainer {
  line-height: 32px;
}
.personalCenterContainer .personalCenterTitle {
  font-size: 16px;
  border-bottom: 1px solid #eeeeee;
}
.personalCenterContainer .personalCenterTitle span {
  display: inline-block;
  padding: 0 15px;
  border-bottom: 2px solid $commonThemeColor;
}
.staffRoleContainer {
  padding: 10px 20px;
}
.staffRoleContainer .addRole {
  text-align: right;
}
.personalCenterContainer .pagination {
  margin-top: 15px;
}
</style>
